<template>
  <div class="rolelist">
    <div class="tit">
        <el-button class="box submit" type="primary" plain icon="el-icon-plus" @click="editAndAddMaterial()">新增</el-button>
        <!-- <el-button class="box submit" type="success" plain icon="el-icon-edit" :disabled="disab" @click="edit()">修改</el-button> -->
        <!-- <el-button class="box submit" type="danger" plain icon="el-icon-delete" :disabled="dis">删除</el-button> -->
        <!-- <el-button class="box submit" type="warning" plain icon="el-icon-download" @click="upload">导出</el-button> -->
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      :header-cell-style="{textAlign:'center'}"
      :cell-style="{textAlign:'center'}">
      <el-table-column
        type="index"
        label="序号"
        width="120">
      </el-table-column>
      <el-table-column
        prop="name"
        label="角色名称">
      </el-table-column>
      <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="editAndAddMaterial(scope.row)" type="text" size="small" icon="el-icon-edit">修改</el-button>
              <el-button type="text" size="small" @click="deleuser(scope.row)" icon="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-size="page"
        :pager-count="9"
        layout="total, prev, pager, next, jumper"
        :total="total"
        hide-on-single-page>
      </el-pagination>
    </div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="680px">
      <el-form :model="form">
        <el-form-item label="角色名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="检察院权限" :label-width="formLabelWidth">
          <el-select v-model="form.region" multiple collapse-tags placeholder="请选择检察院权限">
            <el-option v-for="item in prolist" :key="item.id"  :label="item.procuratoratename" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单权限" :label-width="formLabelWidth">
          <el-select v-model="form.rolelist" multiple collapse-tags placeholder="请选择">
            <el-option v-for="item in options" :key="item.id"  :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="makeSure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getRoleList,getRoleById,getMissCountCity,getAdminRoleAll,AdminAddRole,delAdminAddRole} from "@/utils/api";
import { Message } from 'element-ui';
export default {
  inject: ["reload"],
  data() {
      return {
        tableData: [],
        multipleSelection: [],
        currentPage4: 1,
        page:10,
        total:0,
        options: [],
        prolist:[],
        value2: [],
        dialogFormVisible: false,
        form: {
          rolelist:[],
          name: '',
          region: [],
          num: 1
        },
        formLabelWidth: '120px',
        dialogTitle: '',
			  ifMaterialEdit: 0, // 0表示编辑，1表示新增
        dis:true,
        disab:true,
        idx:'',
      }
  },
  methods: {
    upload(){

    },
    // 获取列表
    getrolelist(){
      getRoleList({
        Limit:this.currentPage4,
        page:this.page,
      }).then(res =>{
        // console.log('角色',res);
        this.tableData = res.data.data.records
        this.total = res.data.data.total
      })
    },
    //所有权限
    getlist(){
      getAdminRoleAll().then(res =>{
        // console.log('院',res);
        this.prolist = res.data.data.procuratorateList
        this.options = res.data.data.permissionsList
      })
    },
    // 角色对应权限
    getrolebyId(id){
      getRoleById({
        roleId:id
      }).then(res =>{
        // if(res.data.data.code == null){
        //     this.dialogFormVisible = true
        // }
        // console.log(res,'角色对应权限');
        let list = res.data.data.map.map(item =>{
          return item.id
        })
        this.form.rolelist=list
        // console.log('菜单',list);
        // this.options = res.data.data.missAllList
      })
    },
    // 角色可以操作的院
    getmisscity(id){
      getMissCountCity({
        roleId:id
      }).then(res =>{
        // console.log(res,'角色可以操作的院');
        // let list = res.data.data.map.map(item =>{
        //   return item.id
        // })
        // this.form.rolelist=list
        // this.prolist = res.data.data.proListAll
        let list = res.data.data.proList.map(item =>{
          return item.id
        })
        // console.log('院',list);
        this.form.region = list
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if(this.multipleSelection.length > 0){
        if(this.multipleSelection.length == 1){
          this.disab = false
        }else{
          this.disab = true
        }
        this.dis = false
      }else{
        this.disab = true
        this.dis = true
      }
      // console.log('table选中',this.multipleSelection);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
      this.currentPage4 = val
      this.getrolelist()
    },
    changeStatus(e,row,index){
      // console.log(e,row,index)
    },
    makeSure() {
      // console.log(this.form.rolelist);
      // console.log(this.form.region);
      // console.log(this.idx);
			let params = {
        id:this.idx,
				roleName: this.form.name,   //权限名
				roleList: this.form.rolelist,   //菜单权限集合
				proList: this.form.region,    //院权限集合
			}
			// 新增接口对接
			if (this.ifMaterialEdit == 1) {
				// console.log('新增')
        AdminAddRole(params).then(res =>{
          // console.log(res);
          if(res.data.code == 200){
            // console.log('保存成功');
            Message({ message:'保存成功',type:"success" })
            this.reload();
          }else{
            // console.log(res.data.msg);
            Message({ message:res.data.msg,type:"warning" })
          }
        })
			} else if (this.ifMaterialEdit == 0) {
				// 编辑接口对接
				// console.log('编辑')
        AdminAddRole(params).then(res =>{
          // console.log(res);
          if(res.data.code == 200){
            // console.log('保存成功');
            Message({ message:'修改成功',type:"success" })
            this.reload();
          }else{
            // console.log(res.data.msg);
            Message({ message:res.data.msg,type:"warning" })
          }
        })
			}
      this.dialogFormVisible = false
		},
    edit(){
      this.ifMaterialEdit = 0
      this.dialogFormVisible = true
      this.dialogTitle = '编辑'
      this.form.name=this.multipleSelection[0].name
      let id = this.multipleSelection[0].id
      this.getrolebyId(id)
      this.getmisscity(id)
    },
    editAndAddMaterial(row) {
      // console.log(row);
			if (row) {
				this.ifMaterialEdit = 0
        this.dialogFormVisible = true
        this.dialogTitle = '编辑'
        // console.log(row)
        this.form.name=row.name
        this.getrolebyId(row.id)
        this.getmisscity(row.id)
        this.idx=row.id
			} else {
				this.ifMaterialEdit = 1
				this.dialogFormVisible = true
				this.dialogTitle = '新增'
        this.form.name=''
        this.form.rolelist=[]
        this.form.region=[]
        this.idx=''
			}
		},
    handleChange(value) {
      // console.log(value);
    },
    // 删除用户
    deleuser(row){
      // console.log(row);
      this.$confirm("是否删除对应角色？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
    }).then(() => {
        delAdminAddRole({reloId:row.id}).then(res =>{
          console.log(res);
          if(res.data.code == 200){
            Message({ message:'删除成功',type:"success" })
            this.reload();
          }
        })
      })
      .catch(() => {
        Message.info("已取消删除!");
      });
    },
  },
  mounted(){
    this.getrolelist()
    this.getlist()
  }
}
</script>

<style  lang="less" scoped>
.rolelist{
  min-height: 95%;
  box-sizing: border-box !important;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 0px rgba(131,131,131,0.1800);
  border-radius: 4px;
  padding: 24px;
}
.block{
  margin-top: 20px;
}
.el-pagination {
    text-align: center; 
}
::v-deep .el-dialog .el-input__inner {
  width: 450px !important;
}
</style>